@use '@/styles/utils/mixins.scss' as *;

.stepsWrapper {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .sectionHeadingContainer {
    display: flex;
    gap: 2.5rem;
    justify-content: space-between;
    margin-bottom: 2rem;

    @include tab() {
      flex-direction: column;
    }
  }

  .stepsContainer {
    display: flex;
    flex-direction: column;

    gap: 2.5rem;

    @include tab() {
      gap: 0rem;
    }

    .stepsRow {
      display: flex;
      gap: 0rem;
      justify-content: center;
      @include tab() {
        flex-direction: column;
      }
      .stepCard {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        flex: 1;
        gap: 1.875rem;
        min-width: 20rem;
        outline: 0;
        padding: 0;
        margin: 0;

        @include tab-des() {
          min-width: 20rem;
        }

        @include tab() {
          min-width: 100%;
          gap: 1.5rem;
          padding: 2.5rem 1.875rem;
        }

        .stepCardCounter {
          display: flex;
          align-items: center;
          gap: 1.25rem;
          width: 100%;

          .stepCardNumber {
            background-color: $primaryAlt;
            color: $white;
            border-radius: 50%;
            width: 3rem;
            height: 3rem;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 1.5rem;
            line-height: normal;
            padding-top: 0.25rem;
            font-weight: 500;
            flex: 0 0 auto;
          }

          &::before,
          &::after {
            content: '';
            width: 100%;
            height: 0.5rem;
            background-color: $primaryAlt;
            flex: 1 1 auto;
            position: relative;
            @include tab() {
              background-color: transparent;
            }
          }

          &::before {
            border-radius: 0 0.5rem 0.5rem 0;
            left: -0.063rem;
          }

          &::after {
            border-radius: 0.5rem 0 0 0.5rem;
          }
        }

        &:first-child {
          .stepCardCounter {
            &::before {
              background-color: transparent;
            }
          }
        }

        &:last-child {
          .stepCardCounter {
            &::after {
              background-color: transparent;
            }
          }
        }

        .stepsCardBody {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 1.25rem;
          padding: 2.5rem 1.875rem;

          @include tab() {
            padding: 0;
          }
        }

        .stepCardTitle {
          font-size: 1.5rem;
          font-weight: 500;
        }

        .stepCardDescription {
          color: $neutral08;
          max-width: 22.125rem;
        }
      }
    }
  }

  .disableTextSelection {
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
  }
}
